<template>
  <div class="category-filter__row">
    <div
      class="category-filter__row__label"
      :class="selected && 'category-filter__row__label__selected'"
    >
      {{ label }}
    </div>
    <div class="category-filter__row__checkbox" v-visible="selected">
      &#10003;
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'CategoryFilterRow',
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});
</script>

<style lang="scss">
.category-filter__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--c-grey);
  margin-bottom: 0.0625rem;
  padding: 0 0.5625rem;
  &__label {
    @include text-16x22-regular;
    overflow-wrap: anywhere;
    &__selected {
      @include text-16x20-black;
    }
  }
  &__checkbox {
    font-size: 1.5rem;
  }
}
@include from-desktop-min {
  .category-filter__row {
    @include pointer;
  }
}
</style>
